import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css'
import firebase from './Firebase';
import { Image, Grid, Container, Button, Message, Form, TextArea } from 'semantic-ui-react'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import moment from 'moment';

// relative import
import './css/App.css';

let array = [];

// eslint-disable-next-line
String.prototype.hashCode = function () {
    if (Array.prototype.reduce) {
      // eslint-disable-next-line
      return this.split('').reduce((a, b) => { a = ((a << 5) - a) + b.charCodeAt(0); return a & a; }, 0);
    }
  
    let hash = 0; let i; let chr; let
      len;
    if (this.length === 0) return hash;
    for (i = 0, len = this.length; i < len; i++) { // eslint-disable-line
      chr = this.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr; // eslint-disable-line
      //  Convert to 32bit integer
      hash |= 0; // eslint-disable-line
    }
    return hash;
  };
  

class App extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.refSurvey = firebase.firestore().collection('places');
        this.refFeedback = firebase.firestore().collection('places');
        this.unsubscribe = null;
        this.state = {
            surveyData: [],
            loading: true,
            isInvalid: false,
            empty: false,
            docId: '',
            thankyou: false, isLoding: false,
            bussinessName: '',
            isActive: true, 
            ltr: -1,
            showThankyou: false,
            feedbackTime: undefined,
        };
        this.onBtnPress = this.onBtnPress.bind(this);
    }

    componentDidMount() {
        const { surveyid, placeid } = this.props.match.params
        const { cookies } = this.props;
        if(surveyid) {
            const cookie = cookies.get(surveyid);
            if (cookie) {
                if(cookie.submitted) {
                    // console.log(cookie.submittedTime)
                    this.setState({ surveyid, placeid, feedbackTime: cookie.submittedTime, showThankyou: true });
                    this.fetchSurveyData(placeid, surveyid);
                    this.props.history.push({ pathname: '/' });
                } else {
                    this.setState({ surveyid, placeid });
                    this.fetchSurveyData(placeid, surveyid);
                    this.props.history.push({ pathname: '/' });
                }
            } else {
                this.setState({ surveyid, placeid });
                this.fetchSurveyData(placeid, surveyid);
                this.props.history.push({ pathname: '/' });
            }
        } else {
            this.setState({ isInvalid: true });
        }
    }

    fetchSurveyData(placeid, surveyid) {
        if(surveyid) {
            this.refSurvey.doc(placeid).get()
            .then((snapshot) => {
                const data = snapshot.data();
                this.setState({ bussinessName: data.name });
            })
            this.refSurvey.doc(placeid).get()
            .then((docs) => {
                const data = docs.data();
                this.setState({ placeName: data.name });
            }).then(() => {
                this.refSurvey.doc(placeid).collection('surveys').where('id', '==', surveyid).get()
                .then(
                    (querySnapshot) => {
                        if(!querySnapshot.empty) {
                            querySnapshot.forEach((doc) => {
                                let data = doc.data();
                                if(data.isActive) {
                                    this.setState({ docId: doc.id, surveyData: doc.data(), loading: false }, () => {
                                        array = Array.from({length: this.state.surveyData.questions.length});
                                    });
                                } else {
                                    this.setState({ isActive: false });
                                }
                            });
                        } else {
                            this.setState({ isInvalid: true, loading: false})
                        }
                });
            });
        }
    }

    renderOption(item, name) {
        if (item.options) {
            if (item.hasOwnProperty('tag')) {
                return (
                    <table>
                        <tbody>    
                        <tr>
                            {item.options.map((option, index) => {
                                return this.optionButton(index, option, item.id, name, item.question);
                            })}
                        </tr>
                        </tbody>
                    </table>
                )
            }
            return item.options.map((items, i) => {
                return (
                    <div key={i}>
                        <input 
                            type="radio" 
                            name={name} 
                            key={i} 
                            value={items} 
                            onChange={() => this.onCheckChange(item.id, items, name, item.question, i)}
                        />
                        <label>&nbsp;&nbsp;&nbsp;{`${items}`}</label><br></br>
                    </div>
                )
            })
        } else {
            return (
                <Form>
                    <TextArea 
                        rows={2}  
                        placeholder='Tell us why...' 
                        onChange={(e) => this.onCheckChange(item.id, e.target.value, name, item.question, 14)}
                    />
                </Form>
            )
        }
    }

    onCheckChange(id, value, name, que, i, from) {
        if(from === 'ltr') {
            this.setState({ ltr: value });
        }
        array[name] = {'id': id, 'answer': value, 'question': que, 'cid': i};
    }

    getDate = () => {
        const timestamp = new Date().getTime();
        const todate = new Date(timestamp).getDate();
        const tomonth = new Date(timestamp).getMonth() + 1;
        const toyear = new Date(timestamp).getFullYear();
        const finalDate = `${tomonth}/${todate}/${toyear}`;
        return finalDate;
    }

    getDateTime = () => {
        const timestamp = new Date().getTime();
        const todate = new Date(timestamp).getDate();
        const tomonth = new Date(timestamp).getMonth() + 1;
        const toyear = new Date(timestamp).getFullYear();
        const hour = new Date(timestamp).getHours();
        const minute = new Date(timestamp).getMinutes();
        const second = new Date(timestamp).getSeconds();
        const finalDate = `${todate}/${tomonth}/${toyear} ${hour}:${minute}:${second}`;
        return finalDate;
    }

    onBtnPress() {
        const { placeid, ltr, surveyid, surveyData } = this.state;
        const { cookies } = this.props;
        const timestamps = firebase.firestore.FieldValue.serverTimestamp();
        if(array.every((element, index) => { return !!element; })) {
            this.setState({ empty: false, isLoding: true });
            const timestamp = new Date().getTime();
            const idString = `${timestamp}${surveyid}`;
            const hashcode = idString.hashCode();
            this.refFeedback.doc(placeid).collection('feedback').add({
                id: hashcode.toString().replace('-', ''),
                result: array,
                surveyid: surveyid,
                created: timestamps,
                updated: timestamps,
                ltr
              }).then(() => {
                const docRef = this.refSurvey.doc(placeid).collection('surveys').doc(this.state.docId);  
                firebase.firestore().runTransaction(
                    transaction => transaction.get(docRef).then((snapshot) => {
                      const total = snapshot.get('totalResponses');
                      transaction.update(docRef, 'totalResponses', total + 1);
                      transaction.update(docRef, 'lastResponse', timestamps);
                      transaction.update(docRef, 'updated', timestamps);
                    })
                  ).then(() => {
                    this.setState({ thankyou: true, isLoding: false});
                    let obj = {};
                    if (surveyData.coupon && surveyData.coupon.expiry) {
                         const { coupon } = surveyData;
                         obj = {'submitted': true, 'submittedTime': +new Date(), expiry: `${coupon.day} ${coupon.time}`}
                         cookies.set(surveyid, obj, { domain: 'survey.feedbacksense.com' }, {expires: new Date(moment().add(coupon.day, coupon.time).format())})
                    } else {
                        obj = {'submitted': true, 'submittedTime': +new Date()};
                        cookies.set(surveyid, obj, { domain: 'survey.feedbacksense.com' });
                    }
                });
            });
        } else {
            this.setState({ empty: true, isLoding: false });
        }
    }

    optionButton = (key, title, id, name, que) => {
        return (
            <td style={{ padding: 5}} key={key}>
                <p>{title}</p>
                <input
                    style={{ width: 18, height: 18 }} 
                    name='radio' 
                    key={key} 
                    type='radio' 
                    value={title} 
                    onChange={(val) => this.onCheckChange(id, title, name, que, key, 'ltr')}
                />
            </td>  
        );
    }

    placename = (item) => {
        const { placeName } = this.state;
        if (item.hasOwnProperty('tag')) {
            const question = item.question.replace('@@@', placeName);
            return question;
        }
        return item.question;
    }

    renderQuestionList() {
        const { surveyData } = this.state;
        const { questions } = surveyData;
        if (questions && questions.length > 0) {
            return questions.map((item, index) => {
                return (
                    <fieldset key={index}>
                        <legend className="q-title">{`${index + 1}. ${this.placename(item)}`}</legend>
                        {this.renderOption(item, index)}
                    </fieldset>
                )
            })
        } 
    }

    _renderInvalid() {
        return(
            <div>
                {this._renderHeader()}
                <div className="ui raised very padded text container segment">
                    <h1 style={{ textAlign: "center" }}>This survey is not available</h1>
                </div>
                {this._renderFooter()}
            </div>
        );
    }

    _renderIsActive() {
        return(
            <div>
                {this._renderHeader()}
                <div className="ui raised very padded text container segment">
                    <h1 style={{ textAlign: "center" }}>This survey is not accepting any responses at the moment</h1>
                </div>
                {this._renderFooter()}
            </div>
        );
    }

    _renderExpiry(coupon) {
        const { feedbackTime } = this.state;
        const timestamps = this.getDateTime();
        const { day, time } = coupon;
        if (feedbackTime) {
           let finalDate = new Date(feedbackTime);
           let finalDates;
           switch (coupon.time) {
                case 'hours':
                finalDate.setHours(finalDate.getHours() + parseInt(day)); // eslint-disable-line
                finalDates = moment(finalDate).format('DD/MM/YYYY HH:mm:ss')
                return (
                    <h4 style={{ textAlign: 'center' }}>{`Expiry in ${moment.utc(moment(finalDates,"DD/MM/YYYY HH:mm:ss").diff(moment(timestamps,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")}`}</h4>
                   );
                case 'days':
                    finalDate.setDate(finalDate.getDate() + parseInt(day)); // eslint-disable-line
                    finalDates = moment(finalDate).format('DD/MM/YYYY HH:mm:ss')
                    return (
                        <h4 style={{ textAlign: 'center' }}>{`Expiry in ${moment.utc(moment(finalDates,"DD/MM/YYYY HH:mm:ss").diff(moment(timestamps,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")}`}</h4>
                       );   
                case 'weeks':
                    finalDate.setDate(finalDate.getDate() + (parseInt(day) * 7)); // eslint-disable-line 
                    finalDates = moment(finalDate).format('DD/MM/YYYY HH:mm:ss')   
                    return (
                        <h4 style={{ textAlign: 'center' }}>{`Expiry in ${moment.utc(moment(finalDates,"DD/MM/YYYY HH:mm:ss").diff(moment(timestamps,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")}`}</h4>
                       );       
                default:
                    break;
            }
        }
        switch (time) {
            case 'hours':
                return (
                    <h4 style={{ textAlign: 'center' }}>{`Expiry in ${day}:00:0 ${time}`}</h4>
                );
            case 'days':
                return (
                    <h4 style={{ textAlign: 'center' }}>{`Expiry in ${day} ${time}`}</h4>
                );    
            case 'weeks':
                return (
                    <h4 style={{ textAlign: 'center' }}>{`Expiry in ${day} ${time}`}</h4>
                );       
            default:
                break;
        }
    }

    _renderThankyou(survey) {
        if(survey.coupon) {
            return(
                <div>
                    {this._renderHeader()}
                    <div className="ui raised very padded text container segment">
                        <h2 style={{ textAlign: "center"}}>
                        {survey.coupon.thankyou && survey.coupon.thankyou.length > 0 ? survey.coupon.thankyou : 'Thank you for completing the survey.'}
                        </h2>
                           {survey.coupon.expiry && this._renderExpiry(survey.coupon)} 
                        </div>
                    {this._renderFooter()}
                </div>
            );
        }
        return (
            <div>
                {this._renderHeader()}
                <div className="ui raised very padded text container segment">
                    <h2 style={{ textAlign: "center"}}>
                        Thank you for completing the survey.
                    </h2>
                    </div>
                {this._renderFooter()}
            </div>
        );
    }

    _renderHeader() {
        return(
            <div className="ui inverted blue vertical footer segment"/>
        );
    }

    _renderFooter() {
        return(
            <div className="ui inverted blue vertical footer segment">
                <h5 style={{ textAlign: "center" }}>Copyright © 2019 Feedback Sense.</h5>
            </div>
        );
    }

    renderLogo = (url) => {
        if(url) {
            return(
                <Image className="imgLogo" circular src={url} />
            )
        }
        return null;
    }

    renderName = () => {
        const { bussinessName } = this.state;
        if (bussinessName) {
            return (
                <h4>{bussinessName}</h4>
            )
        }
        return null;
    }

    render() {
        const { loading, surveyData, isInvalid, empty, thankyou, isLoding, isActive, showThankyou } = this.state;
        if(isInvalid) {
            return this._renderInvalid();
        }
        if(thankyou) {
            return this._renderThankyou(surveyData);
        }
        if(!isActive) {
            return this._renderIsActive();
        }
        if(showThankyou) {
            return this._renderThankyou(surveyData);
        }
        return (
            <div>
                <Container>
                    <Grid centered verticalAlign='middle' columns={2}>
                        <Grid.Column style={{width: '130px'}}>
                           {!loading && this.renderLogo(surveyData.logo_url)}
                        </Grid.Column>
                        <Grid.Row centered>
                            <Grid.Column textAlign='center'>
                                {this.renderName()}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column mobile={14}>
                            {loading ? <h1 style={{ textAlign: 'center' }}>Loading the survey questions...</h1> : this.renderQuestionList()}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Button 
                                    loading={isLoding}
                                    className="btn" 
                                    content='Submit' 
                                    primary 
                                    onClick={() => this.onBtnPress()}
                                />
                               {empty && <Message >Please fill all the fields</Message>} 
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column >
                                
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
                {this._renderFooter()}
            </div>
        );
    }
}

export default withCookies(App);
