import * as firebase from 'firebase';

const settings = {timestampsInSnapshots: true};
var config = {
    apiKey: "AIzaSyAdIhcp26sRlzi0aMAyHuv5RA8SFco5uss",
    authDomain: "feedbacksense-1.firebaseapp.com",
    databaseURL: "https://feedbacksense-1.firebaseio.com",
    projectId: "feedbacksense-1",
    storageBucket: "feedbacksense-1.appspot.com",
    messagingSenderId: "54640668720"
  };
firebase.initializeApp(config);

firebase.firestore().settings(settings);

export default firebase;