import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router';
import { Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import createBrowserHistory from 'history/createBrowserHistory';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const customHistory = createBrowserHistory();
const Root = () => {
 return (
  <CookiesProvider> 
    <Router history={customHistory}>
    <Switch>
        <Route path="/:placeid/:surveyid" component={App} />
        <Route path="/" component={App} />
    </Switch>
    </Router> 
  </CookiesProvider>  
 )
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();
